<template>
  <div>
    <a-card title="生产计划详情">
      <template slot="extra">
        <a-space>
          <a-button type="primary" ghost icon="printer" v-print="'#printContent'">打印</a-button>
          <a-button type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <section id="printContent">
        <a-spin :spinning="getProductionOrderLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="生产工单号">
              {{ item.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售单号">
              {{ item.sales_number }}
            </a-descriptions-item>
            <a-descriptions-item label="状态">
              {{ item.status_display }}
            </a-descriptions-item>
            <a-descriptions-item label="物料编号">
              {{ item.goods_number }}
            </a-descriptions-item>
            <a-descriptions-item label="物料名称">
              {{ item.goods_name }}
            </a-descriptions-item>
            <a-descriptions-item label="物料条码">
              {{ item.goods_barcode }}
            </a-descriptions-item>
            <a-descriptions-item label="物料规格">
              {{ item.goods_spec }}
            </a-descriptions-item>
            <a-descriptions-item label="计划数量">
              {{ item.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="完成数量">
              {{ item.quantity_produced }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ item.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="计划开始时间">
              {{ item.planned_end_time }}
            </a-descriptions-item>
            <a-descriptions-item label="计划完成时间">
              {{ item.planned_end_time }}
            </a-descriptions-item>
            <a-descriptions-item label="批号">
              {{ item.batch_number }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ item.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ item.creator_name }}
            </a-descriptions-item>
          </a-descriptions>

          <a-divider orientation="left" style="margin-top: 30px">BOM清单</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :loading="getBOMListLoading"
            :columns="tableColumns"
            :dataSource="productBOMItems"
            :pagination="false"
          />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { productionOrderDetail } from "@/api/production";
import { goodsBOMList } from "@/api/goods";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      getProductionOrderLoading: false,
      getBOMListLoading: false,
      item: {},
      productBOMItems: [],

      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "物料名称",
          dataIndex: "product_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "物料编号",
          dataIndex: "product_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "物料条码",
          dataIndex: "product_barcode",
          customRender: (_, item) => item.goods_item.barcode,
        },
        {
          title: "物料规格",
          dataIndex: "product_spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "物料尺寸",
          dataIndex: "product_drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "物料分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
      ],
    };
  },
  methods: {
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
    initData() {
      this.getProductionOrderLoading = true;
      productionOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.item = data;
          this.getJsBarcode(data.number);
          this.getProductBOMList(data.goods);
        })
        .finally(() => {
          this.getProductionOrderLoading = false;
        });
    },
    getProductBOMList(id) {
      this.getBOMListLoading = true;
      goodsBOMList({ id })
        .then((data) => {
          this.productBOMItems = data;
        })
        .finally(() => {
          this.getBOMListLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
